import { useEffect } from 'react';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-MNTGRDZLVB'
}


const GoogleAnalytics = () => {
    TagManager.initialize(tagManagerArgs)

    return null;
};

export default GoogleAnalytics;
